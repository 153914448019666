<template>
  <ValidationObserver ref="observer" slim>
    <ValidationProvider
      ref="provider"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group
        :id="`group-${id}`"
        :label="label"
        :label-for="`input-${id}`"
        :label-cols="computedLabelCols['xs']"
        :label-cols-sm="computedLabelCols['sm']"
        :label-cols-md="computedLabelCols['md']"
        :label-cols-lg="computedLabelCols['lg']"
        :label-cols-xl="computedLabelCols['xl']"
        :label-class="computedLabelClass"
        :description="description"
      >
        <b-input-group :prepend="prepend" :append="append">
          <template v-if="!!$slots.prepend" #prepend>
            <slot name="prepend"></slot>
          </template>
          <b-form-input
            ref="input"
            :id="`input-${id}`"
            :name="`input-${id}`"
            :type="type"
            :disabled="computedDisabled"
            :state="getValidationState(validationContext)"
            :aria-describedby="`feedback-${id}`"
            :placeholder="placeholder"
            :value="value"
            @input="onInput"
            @change="(value) => $emit('change', value)"
          ></b-form-input>
          <template v-if="!!$slots.append" #append>
            <slot name="append"></slot>
          </template>
        </b-input-group>
        <b-form-invalid-feedback
          :id="`feedback-${id}`"
          :state="getValidationState(validationContext)"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { computed as inputsComputed } from "../../services/inputs.service";
import {
  computed as validationComputed,
  methods as validationMethods,
} from "../../services/validation.service";
export default {
  name: "ValidatedInputComponent",
  components: { ValidationObserver, ValidationProvider },
  props: {
    // valeur de l'input
    value: undefined,
    // type de l'input ("text" par défaut)
    type: {
      type: String,
      default: "text",
    },
    // prepend de l'input group
    prepend: String,
    // append de l'input group
    append: String,
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété placeholder de l'input
    placeholder: [String, Number],
    // propriété description du form group
    description: String,
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  data: () => ({
    id: uuidv4(),
  }),
  computed: {
    ...inputsComputed,
    ...validationComputed,
  },
  methods: {
    ...validationMethods,
  },
};
</script>
