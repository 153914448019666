<template>
  <GenericReadonlyComponent
    v-if="computedReadOnly"
    :label="label"
    :labelCols="labelCols"
    :quickEdit="quickEdit"
    @edit="$emit('edit')"
    :value="value"
  >
    <slot></slot>
  </GenericReadonlyComponent>
  <UnvalidatedInputComponent
    v-else-if="rules == null"
    ref="unvalidatedInput"
    :label="label"
    :labelCols="labelCols"
    :description="description"
    :prepend="prepend"
    :append="append"
    :disabled="disabled"
    :type="type"
    :placeholder="placeholder"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  >
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </UnvalidatedInputComponent>
  <ValidatedInputComponent
    v-else
    ref="validatedInput"
    :label="label"
    :labelCols="labelCols"
    :description="description"
    :prepend="prepend"
    :append="append"
    :disabled="disabled"
    :rules="rules"
    :type="type"
    :placeholder="placeholder"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  >
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </ValidatedInputComponent>
</template>

<script>
import GenericReadonlyComponent from "./GenericReadonlyComponent.vue";
import UnvalidatedInputComponent from "./UnvalidatedInputComponent.vue";
import ValidatedInputComponent from "./ValidatedInputComponent.vue";
export default {
  name: "CustomInputComponent",
  components: {
    GenericReadonlyComponent,
    UnvalidatedInputComponent,
    ValidatedInputComponent,
  },
  props: {
    // valeur de l'input
    value: undefined,
    // step de l'input
    type: {
      type: String,
      default: "text",
    },
    // prepend de l'input group
    prepend: String,
    // append de l'input group
    append: String,
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété placeholder de l'input
    placeholder: [String, Number],
    // propriété description du form group
    description: String,
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour autoriser l'édition rapide (false par défaut)
    quickEdit: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  computed: {
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
  },
  methods: {
    async focus() {
      if (this.computedReadOnly) {
        return;
      }
      if (this.rules == null) {
        this.$refs.unvalidatedInput.focus();
      } else {
        this.$refs.validatedInput.focus();
      }
    },
    async validate() {
      await this.$refs.validatedInput?.validate();
    },
  },
};
</script>
